<template>

    <div id="app" class="wrapper">
      <router-view v-wechat-title="$route.meta.title" :key="$route.fullPath"></router-view>
      <loading-comp v-if="$store.state.isLoading"></loading-comp>
    </div>

</template>

<style>
  @import url("assets/css/base.css");
  @import url("assets/css/style.css");
  @import url("assets/css/media.css");
  @import url("assets/css/sports.css");

</style>
<script>
  import TopbarComp from "./components/TopbarComp";
  import FootComp from "./components/FootComp";
  import LoadingComp from "./components/LoadingComp";
  import {RECEIVE_DISPLAY_POSTION, RECEIVE_HIDE_LOADING} from "./store/mutation-types";
  import sportsConst from "./common/sportsConst";

  export default {
    components: {LoadingComp, FootComp, TopbarComp},
    data() {
      return {}
    },
    created() {
        if (sessionStorage.getItem('state')) {
            this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem('state'))))
        }
        window.addEventListener('pagehide', (ev) => {
            this.$store.commit(RECEIVE_DISPLAY_POSTION, sportsConst.GAME_DISPLAYPOSITION_1x2);
            this.$store.commit(RECEIVE_HIDE_LOADING);
            this.$store.state.sportsCartItems = []
            sessionStorage.setItem('state', JSON.stringify(this.$store.state))
            return false
        })
    },
    watch: {
      $route(to, from) {

      }
    },
  }
</script>
<style scoped>

</style>