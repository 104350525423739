<template>
  <div>
    <div class="popup_section" v-for="(item,index) in plist"
         :style="[item.styObject]"
         :key="index"
         v-show="item.isShow">

      <div class="pop_box">
        <h2>
          <span @click="close24(item)" style="cursor: pointer">
             <i class="fa fa-check-square"></i> 오늘하루 그만보기
          </span>
        </h2>
        <span class="close_pop" @click="close24(item)"><i class="fa fa-close"></i></span>
        <div class="event_img">
          <img v-if="item.type === 2" :src="item.imageUrl" alt="">
          <span v-else v-html="item.content"> </span>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
  import {getPopupList} from "../network/userRequest";

  export default {
    name: "PopupComp",
    data() {
      return {
        plist: []
      }
    },
    methods: {
      close24(item) {
        this.$set(item, 'isShow', false)
        this.$cookies.set('pop' + item.id, '1', 60 * 60 * 12, '/')
      },
      getPopList() {
        getPopupList().then(res => {
          if (res.data.success) {
            this.plist = res.data.data
            let i = 0;
            let preLeft = 380;
            let preWidth = 0;
            this.plist.forEach(popup => {

              let cookiePopupStatus = this.$cookies.get('pop' + popup.id)
              this.$set(popup, 'isShow', cookiePopupStatus == null)


              if (i > 0) {
                preLeft = preLeft + preWidth + 10
              }

              popup.styObject = {
                left: preLeft + 'px',
                top: 66 + 'px',
                opacity: 1,
                width: popup.width + 'px',
                height: popup.height + 'px'
              }
              i++;
              preWidth = popup.width
            })
          }
        })
      }
    },
    created() {
      this.getPopList()
    }
  }
</script>

<style scoped>
  .pop_box h2 span:hover {
    color: #f19f09;
  }

  .close_pop:hover {
    color: #f19f09;
  }

  @media screen and (max-width: 800px) {
    .popup_section {
      top: 0 !important;
      left: 0 !important;
    }
  }
</style>