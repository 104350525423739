// axios
import axios from 'axios'
import {BASE_URL,TIMEOUT} from "../../common/const";
import store from '../../store'
import router from "../../router";
import {RECEIVE_SHOW_LOADING, RECEIVE_HIDE_LOADING, RECEIVE_IS_LOGIN} from "../../store/mutation-types";

//1. create new axios instance
const service = axios.create({
  baseURL: BASE_URL,
  timeout: TIMEOUT,
});
//Send with Cookie
service.defaults.withCredentials = true
service.defaults.crossDomain = true

// 2.Response Interceptors
service.interceptors.request.use(config => {
  if(config.url.indexOf('/sports/inplay/games') === -1){
    store.commit(RECEIVE_SHOW_LOADING);
  }
  return config

}, error => {
  store.commit(RECEIVE_HIDE_LOADING);
  Promise.reject(error)
})

// 3.Response Interceptor
service.interceptors.response.use(response => {
  store.commit(RECEIVE_HIDE_LOADING);
  if(response.data.status === 444){
    router.push('/login');
    store.commit(RECEIVE_IS_LOGIN, false);
  }
  if(response.data.status === 555){
    router.push('/login');
    store.commit(RECEIVE_IS_LOGIN, false);
  }
  if(response.data.status === 666){
    router.push('/login');
    store.commit(RECEIVE_IS_LOGIN, false);
  }

  return response
}, error => {
  /***** Response Error *****/
  store.commit(RECEIVE_HIDE_LOADING);
  if (error && error.response) {
    console.log(error)
    switch (error.response.status) {
      case 400:
        error.message = 'Error Request 400'
        break;
      case 401:
        error.message = 'Login Please'
        break;
      case 403:
        error.message = 'Error 403'
        break;
      case 404:
        error.message = 'Error 404'
        break;
      case 405:
        error.message = 'The Request Method Not Allowed'
        break;
      case 408:
        error.message = 'Request Time Out'
        break;
      case 500:
        error.message = 'Server Exception'
        break;
      case 501:
        error.message = 'Network Error 501'
        break;
      case 502:
        error.message = 'Network Error 502'
        break;
      case 503:
        error.message = 'Server error'
        break;
      case 504:
        error.message = 'Internet Time Out'
        break;
      case 505:
        error.message = 'Error Http Version'
        break;
      default:
        error.message = `Connect Error ${error.response.status}`
    }
  } else {
    if (JSON.stringify(error).includes('timeout')) {
      console.error('Server Response Over Time, Please Refresh')
    }
    error.message = 'Connect Server Error'
  }

  console.error(error.message)
  /***** END *****/
  return Promise.resolve(error.response)
})

export default service
