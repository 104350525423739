<template>
  <div>

    <popup-comp></popup-comp>

    <!--읽지 않은 메세지-->
    <message-comp></message-comp>

    <div class="foot">
      <div class="zc">
        <router-link to="/tearm1" tag="div" class="tx" style="cursor: pointer">이용약관</router-link>
        <router-link to="/tearm2" tag="div" class="tx" style="cursor: pointer">운영정책</router-link>
        <router-link to="/tearm3" tag="div" class="tx" style="cursor: pointer">개인정보처리방침</router-link>
        <router-link to="/tearm4" tag="div" class="tx" style="cursor: pointer">이메일무단수집거부 </router-link>
        <router-link to="/tearm5" tag="div" class="tx" style="cursor: pointer">제휴문의</router-link>
      </div>
      <div class="addr">
        <div>
          <p>(주)이한소프트 대표: 한남수</p>
          <p>사업자등록번호 : 367-87-01451</p>
          <p>통신판매업 등록 번호 : 2019-성남분당 B-0452호</p>
          <p>주소 : 경기도 성남시 분당구 판교역로192번길 22</p>
          <p>고객센터 : 031-707-5561 (상담시간 : 10:00 ~ 19:00 주말, 공휴일 휴무)</p>
          <p>ⓒONEPLAY. All rights reserve</p>
        </div>
        
        <div class="img_paner">
          <div class="i">
            <img src="../assets/images/foot/footer_image.png" alt="" style="height: 70px">
          </div>
          <div class="t">
            <p>Oneplay의 모든 컨텐츠 저작권은 ㈜이한소프트에 있으며 이를 이용하는 경우 저작권법 등에 따라 법적책임을 질 수 있습니다.</p>
            <p>Oneplay의 게임물 등급분류번호 제 CC-NP-190807-004 호 입니다.</p>
            <p>Oneplay는 게임물관리위원회의 승인을 받았습니다.</p>
          </div>
        </div>

      </div>

    </div>
  </div>


</template>

<script>
  import MessageComp from "./MessageComp";
  import PopupComp from "./PopupComp";
  export default {
    name: "FootComp",
    components: {PopupComp, MessageComp},
    created() {
    }
  }
</script>

<style scoped>

  .foot {
    position: relative;
    box-sizing: border-box;
    text-align: center;
    background-color: #323232;
    margin: 0 280px 0 280px;
    color: #c4c4c3;
  }
  .foot .zc{
    width: 100%;
    height: 60px;
    line-height: 60px;
    text-align: left;
    box-sizing: border-box;
    display: flex;
    justify-content: left;
    border-bottom: 1px solid #383838;
    flex-wrap: wrap;
  }
  .foot .zc .tx{
    box-sizing: border-box;
    padding: 0 20px;
    color: #c4c4c3;
    font-size: 11px;
  }
  .foot .addr{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .addr div{
    width: 49.5%;
    font-size: 12px;
    text-align: left;
    box-sizing: border-box;
    padding: 20px;
  }
  .foot .img_paner{
    display: flex;
    justify-content: space-between;
  }
  .foot .img_paner .i{
    width: 30%;
  }
  .foot .img_paner .t{
    width: 69.8%;
  }
  @media screen and (max-width: 1024px) {
    .foot .zc{
      height: 120px;
    }
    .foot .zc .tx{
      width: 33.3%!important;
      padding: 0;
      text-align: center;
    }
    .addr div{
      width: 100%!important;
    }

  }

</style>