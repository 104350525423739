export default {

  LEISURE_KIND_POWERBALL: 1,
  LEISURE_KIND_POWERSADALI: 2,
  LEISURE_KIND_SPEEDKINO: 3,
  LEISURE_KIND_KINOSADALI: 4,
  LEISURE_KIND_NAMED_DALIDALI: 10,
  LEISURE_KIND_NAMED_SADALI: 11,
  LEISURE_KIND_NAMED_SPEEDSADALI: 12,
  LEISURE_KIND_NAMED_SNAIL: 13,
  LEISURE_KIND_FX_1M: 20,
  LEISURE_KIND_FX_2M: 21,
  LEISURE_KIND_FX_3M: 22,
  LEISURE_KIND_FX_4M: 23,
  LEISURE_KIND_FX_5M: 24,
  LEISURE_KIND_BET365_DOGRACE_GOLDENHILLPARK: 40,
  LEISURE_KIND_BET365_DOGRACE_HILLSIDEPARK: 41,
  LEISURE_KIND_BET365_SOCCER_PREMIERSHIP: 45,
  LEISURE_KIND_BET365_SOCCER_SUPERLEAGUE: 46,
  LEISURE_KIND_BET365_SOCCER_EUROCUP: 47,
  LEISURE_KIND_BET365_SOCCER_WORLDCUP: 48,

  LEISURE_KIND_BAKARA_LOTUS_1: 25,
  LEISURE_KIND_BAKARA_LOTUS_2: 26,
  LEISURE_KIND_BAKARA_MGM: 27,
  LEISURE_KIND_ODDEVEN_LOTUS: 28,
  LEISURE_KIND_ODDEVEN_MGM: 29,

  //레이저 게임 속성
  //파워볼
  LEISURE_ATTRIBUTE_LOTTO_POWERBALL: 0,
  P_POWERBALL_NUMBER: 1,
  P_POWERBALL_INTERVAL: 2,
  P_POWERBALL_OVERUNDER: 3,
  P_POWERBALL_ODD_EVEN: 4,
  P_POWERBALL_ODDEVEN_UNDEROVER: 5,
  //일반볼 합계
  P_NORMALBALL_INTERVAL: 6,
  P_NORMALBALL_OVERUNDER: 7,
  P_NORMALBALL_ODD_EVEN: 8,
  P_NORMALBALL_BMS: 9,
  P_NORMALBALL_ODDEVEN_UNDEROVER: 10,
  P_NORMALBALL_ODDEVEN_BMS: 11,

  //파워사다리 홀짝 , 좌우측, 3줄4줄
  LEISURE_ATTRIBUTE_POWERSADALI_ODDEVEN: 20,
  LEISURE_ATTRIBUTE_POWERSADALI_LEFT_RIGHT: 21,
  LEISURE_ATTRIBUTE_POWERSADALI_LINE_COUNT: 22,

  //스피드키노 홀짝 ,오버언더
  LEISURE_ATTRIBUTE_SPEEDKINO_ODDEVEN: 26,
  LEISURE_ATTRIBUTE_SPEEDKINO_OVERUNDER: 27,

  //키노사다리 홀짝 , 좌우측, 3줄4줄
  LEISURE_ATTRIBUTE_KINOSADALI_ODDEVEN: 30,
  LEISURE_ATTRIBUTE_KINOSADALI_LEFT_RIGHT: 31,
  LEISURE_ATTRIBUTE_KINOSADALI_LINE_COUNT: 32,

  //네임드 달팽이 홀짝 , 오버언더, 우승번호
  LEISURE_ATTRIBUTE_NAMEDSNAIL_ODDEVEN: 50,
  LEISURE_ATTRIBUTE_NAMEDSNAIL_OVERUNDER: 51,
  LEISURE_ATTRIBUTE_NAMEDSNAIL_WINNUMBER: 52,

  //네임드 다리다리
  LEISURE_ATTRIBUTE_NAMEDDALIDALI_ODDEVEN: 35,
  LEISURE_ATTRIBUTE_NAMEDDALIDALI_LEFT_RIGHT: 36,
  LEISURE_ATTRIBUTE_NAMEDDALIDALI_LINE_COUNT: 37,
  //네임드 사다리
  LEISURE_ATTRIBUTE_NAMEDSADALI_ODDEVEN: 40,
  LEISURE_ATTRIBUTE_NAMEDSADALI_LEFT_RIGHT: 41,
  LEISURE_ATTRIBUTE_NAMEDSADALI_LINE_COUNT: 42,
  //네임드 speed사다리
  LEISURE_ATTRIBUTE_NAMEDSPEEDSADALI_ODDEVEN: 45,
  LEISURE_ATTRIBUTE_NAMEDSPEEDSADALI_LEFT_RIGHT: 46,
  LEISURE_ATTRIBUTE_NAMEDSPEEDSADALI_LINE_COUNT: 47,

  //FX
  //FX1M
  LEISURE_ATTRIBUTE_FX_1M_ODDEVEN: 100,
  LEISURE_ATTRIBUTE_FX_1M_OVERUNDER: 101,
  LEISURE_ATTRIBUTE_FX_1M_JG_SELLBUY: 102,
  LEISURE_ATTRIBUTE_FX_1M_CG_SELLBUY: 103,
  //FX2M
  LEISURE_ATTRIBUTE_FX_2M_ODDEVEN: 104,
  LEISURE_ATTRIBUTE_FX_2M_OVERUNDER: 105,
  LEISURE_ATTRIBUTE_FX_2M_JG_SELLBUY: 106,
  LEISURE_ATTRIBUTE_FX_2M_CG_SELLBUY: 107,
  //FX3M
  LEISURE_ATTRIBUTE_FX_3M_ODDEVEN: 108,
  LEISURE_ATTRIBUTE_FX_3M_OVERUNDER: 109,
  LEISURE_ATTRIBUTE_FX_3M_JG_SELLBUY: 110,
  LEISURE_ATTRIBUTE_FX_3M_CG_SELLBUY: 111,
  //FX4M
  LEISURE_ATTRIBUTE_FX_4M_ODDEVEN: 112,
  LEISURE_ATTRIBUTE_FX_4M_OVERUNDER: 113,
  LEISURE_ATTRIBUTE_FX_4M_JG_SELLBUY: 114,
  LEISURE_ATTRIBUTE_FX_4M_CG_SELLBUY: 115,
  //FX5M
  LEISURE_ATTRIBUTE_FX_5M_ODDEVEN: 116,
  LEISURE_ATTRIBUTE_FX_5M_OVERUNDER: 117,
  LEISURE_ATTRIBUTE_FX_5M_JG_SELLBUY: 118,
  LEISURE_ATTRIBUTE_FX_5M_CG_SELLBUY: 119,

  LEISURE_ATTRIBUTE_BET365_DOGRACE_GOLDENHILLPARK_NORMAL: 60,
  LEISURE_ATTRIBUTE_BET365_DOGRACE_HILLSIDEPARK_NORMAL: 61,
  //Bet365 가상축구
  LEISURE_ATTRIBUTE_BET365_SOCCER_PREMIERSHIP_1x2: 62,
  LEISURE_ATTRIBUTE_BET365_SOCCER_PREMIERSHIP_OVERUNDER: 63,
  LEISURE_ATTRIBUTE_BET365_SOCCER_SUPERLEAGUE_1x2: 64,
  LEISURE_ATTRIBUTE_BET365_SOCCER_SUPERLEAGUE_OVERUNDER: 65,
  LEISURE_ATTRIBUTE_BET365_SOCCER_EUROCUP_1x2: 66,
  LEISURE_ATTRIBUTE_BET365_SOCCER_EUROCUP_OVERUNDER: 67,
  LEISURE_ATTRIBUTE_BET365_SOCCER_WORLDCUP_1x2: 68,
  LEISURE_ATTRIBUTE_BET365_SOCCER_WORLDCUP_OVERUNDER: 69,

  //바카라 lotus
  LEISURE_ATTRIBUTE_BAKARA_LOTUS_1_NORMAL: 90,
  LEISURE_ATTRIBUTE_BAKARA_LOTUS_1_ODDEVEN: 96,


  //바카라 mgm
  LEISURE_ATTRIBUTE_BAKARA_MGM_NORMAL: 95,
  LEISURE_ATTRIBUTE_BAKARA_MGM_ODDEVEN: 97,

  //게임결과 정상입력 1 ,  waiting:cancel:specialcase일경우 2
  LEISURE_RESULT_CANCEL_TEXT: 'cancel',
  LEISURE_RESULT_SPECIALCASE_TEXT: 'specialcase',

}