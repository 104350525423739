<template>
    <!--메인메뉴 패널-->
    <div>
        <div class="mobile-menu-wapper" :class="{'activeMobileMenu':isShowTopMenu}">
            <div class="close">
                <a class="btn btn02" @click="closeMobileMenu()">
                    <i class="fa fa-sign-out" aria-hidden="true"></i>닫기
                </a>
            </div>
            <div class="content">
                <div class="menu-item">
                    <div class="menu-desc">
                        <router-link to="/sports" @click.native="closeMobileMenu(cross)" tag="div" class="menue-link">
                            <i class="fa fa-futbol-o" aria-hidden="true"></i>
                            <span>스포츠</span>
                        </router-link>
                    </div>
                </div>
                <div class="menu-item">
                    <div class="menu-desc">
                        <router-link to="/sports_inplay" @click.native="closeMobileMenu(0)" tag="div"
                                     class="menue-link">
                            <i class="fa fa-futbol-o" aria-hidden="true"></i>
                            <span>실시간</span>
                        </router-link>
                    </div>
                </div>

                <div class="menu-item">
                    <div class="menu-desc">
                        <router-link to="/sports_betinfo" @click.native="closeMobileMenu(0)" tag="div"
                                     class="menue-link">
                            <i class="fa fa-bold" aria-hidden="true"></i>
                            <span>참여내역</span>                        </router-link>
                    </div>
                </div>
                <div class="menu-item">
                    <div class="menu-desc">
                        <router-link to="/sports_result" @click.native="closeMobileMenu(0)" tag="div"
                                     class="menue-link">
                            <i class="fa fa-hourglass-end" aria-hidden="true"></i>
                            <span>경기결과</span>
                        </router-link>
                    </div>
                </div>

                <div class="menu-item">
                    <div class="menu-desc">
                        <router-link to="/message" @click.native="closeMobileMenu(0)" tag="div" class="menue-link">
                            <i class="fa fa-envelope" aria-hidden="true"></i>
                            <span>쪽지</span>
                        </router-link>
                    </div>
                </div>
                <div class="menu-item">
                    <div class="menu-desc">
                        <router-link to="/customer_center" @click.native="closeMobileMenu(0)" tag="div"
                                     class="menue-link">
                            <i class="fa fa-comments" aria-hidden="true"></i>
                            <span>고객센터</span>
                        </router-link>
                    </div>
                </div>
                <div class="menu-item">
                    <div class="menu-desc">
                        <router-link to="/notice" @click.native="closeMobileMenu(0)" tag="div" class="menue-link">
                            <i class="fa fa-list-alt" aria-hidden="true"></i>
                            <span>공지사항</span>
                        </router-link>
                    </div>
                </div>
                <div class="menu-item">
                    <div class="menu-desc">
                        <router-link to="/faq" @click.native="closeMobileMenu(0)" tag="div" class="menue-link">
                            <i class="fa fa-list-alt" aria-hidden="true"></i>
                            <span>FAQ</span>
                        </router-link>
                    </div>
                </div>
                <div class="menu-item">
                    <div class="menu-desc">
                        <router-link :to="{path:'/event'}" @click.native="closeMobileMenu(0)" tag="div"
                                     class="menue-link">
                            <i class="fa fa-bar-chart" aria-hidden="true"></i>
                            <span>이벤트</span>
                        </router-link>
                    </div>
                </div>
                <div class="menu-item">
                    <div class="menu-desc">
                        <router-link to="/recharge" @click.native="closeMobileMenu(cross)" tag="div" class="menue-link">
                            <i class="fa fa-krw" aria-hidden="true"></i>
                            <span>코인충전</span>
                        </router-link>
                    </div>
                </div>
                <div class="menu-item">
                    <div class="menu-desc">
                        <router-link to="/recharge_history" @click.native="closeMobileMenu(cross)" tag="div" class="menue-link">
                            <i class="fa fa-krw" aria-hidden="true"></i>
                            <span>충전내역</span>
                        </router-link>
                    </div>
                </div>
                <div class="menu-item">
                    <div class="menu-desc">
                        <router-link to="/item_shop" @click.native="closeMobileMenu(cross)" tag="div" class="menue-link">
                            <i class="fa fa-cart-plus" aria-hidden="true"></i>
                            <span>아이템샵</span>
                        </router-link>
                    </div>
                </div>
                <div class="menu-item">
                    <div class="menu-desc">
                        <router-link to="/board" @click.native="closeMobileMenu(cross)" tag="div" class="menue-link">
                            <i class="fa fa-dashcube" aria-hidden="true"></i>
                            <span>자유게시판</span>
                        </router-link>
                    </div>
                </div>
                <div class="menu-item">
                    <div class="menu-desc">
                        <router-link to="/analysis" @click.native="closeMobileMenu(cross)" tag="div" class="menue-link">
                            <i class="fa fa-anchor" aria-hidden="true"></i>
                            <span>분석게시판</span>
                        </router-link>
                    </div>
                </div>
               <!-- <div class="menu-item">
                    <div class="menu-desc">
                        <router-link to="/sell_list" @click.native="closeMobileMenu(0)" tag="div" class="menue-link">
                            <i class="fa fa-hand-grab-o" aria-hidden="true"></i>
                            <span>거래소</span>
                        </router-link>
                    </div>
                </div>
                <div class="menu-item">
                    <div class="menu-desc">
                        <router-link to="/sell_sportsbetinfo" @click.native="closeMobileMenu(0)" tag="div" class="menue-link">
                            <i class="fa fa-sellsy" aria-hidden="true"></i>
                            <span>판매등록</span>
                        </router-link>
                    </div>
                </div>
                <div class="menu-item">
                    <div class="menu-desc">
                        <router-link to="/sell_history" @click.native="closeMobileMenu(0)" tag="div" class="menue-link">
                            <i class="fa fa-sellsy" aria-hidden="true"></i>
                            <span>판매내역</span>
                        </router-link>
                    </div>
                </div>
                <div class="menu-item">
                    <div class="menu-desc">
                        <router-link to="/buy_history" @click.native="closeMobileMenu(0)" tag="div" class="menue-link">
                            <i class="fa fa-sellsy" aria-hidden="true"></i>
                            <span>구매내역</span>
                        </router-link>
                    </div>
                </div>-->
                <div class="menu-item">
                    <div class="menu-desc">
                        <router-link to="/user_info" @click.native="closeMobileMenu(0)" tag="div" class="menue-link">
                            <i class="fa fa-user-circle" aria-hidden="true"></i>
                            <span>회원정보</span>
                        </router-link>
                    </div>
                </div>
                <div class="menu-item">
                    <div class="menu-desc">
                        <router-link to="/betrule" @click.native="closeMobileMenu(0)" tag="div" class="menue-link">
                            <i class="fa fa-list-alt" aria-hidden="true"></i>
                            <span>게임가이드</span>
                        </router-link>
                    </div>
                </div>
            </div>

        </div>

        <!-- <div class="menu-panel" :class="{'activeMobileMenu':isShowTopMenu}">





         </div>-->

    </div>

</template>

<script>
    import sportsConst from "../../common/sportsConst";
    import leisureConst from "../../common/leisureConst";

    export default {
        name: "MobileTopMenu",
        data() {
            return {
                sportsConst,
                leisureConst,
                isShowTopMenu: false,
                cross: sportsConst.GAME_DISPLAYPOSITION_1x2,
                handicap: sportsConst.GAME_DISPLAYPOSITION_HANDICAPOVERUNDER,
                special: sportsConst.GAME_DISPLAYPOSITION_SPECIAL,
                live: sportsConst.GAME_DISPLAYPOSITION_LIVE
            }
        },
        methods: {
            closeMobileMenu(disp) {
                this.isShowTopMenu = false
                if (disp) {
                    this.$bus.$emit('sportsDisplayChanged', disp);
                }
            }
        },
        created() {
            this.$bus.$on('mobileTopMenuActive', () => {
                this.isShowTopMenu = true;
            })
        }
    }
</script>

<style scoped>




</style>