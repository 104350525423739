<template>

    <div class="header">
        <div class="top">
            <div class="top_menu01">
                <div class="pc_section">
                    <router-link tag="div" to="/main" class="pc_logo">
                        <img src="../assets/images/logo/oneplay/oneplay_logo.png" alt="">
                    </router-link>
                    <div class="pc_menus">
                        <a class="menu_item">게임센터</a>
                        <!--<a class="menu_item">거래소</a>-->
                        <a class="menu_item">아이템샵</a>
                        <a class="menu_item">커뮤니티</a>
                        <a class="menu_item">고객센터</a>
                        <div class="menu_modal">
                            <div class="sub_menu_list">
                                <div class="sub_menu">
                                    <router-link tag="span" :to="{path: '/sports', query: {s: new Date().getTime()}}" active-class="">스포츠</router-link>
                                    <router-link tag="span" :to="{path: '/sports_inplay', query: {s: new Date().getTime()}}"  active-class="">실시간 스포츠</router-link>
                                    <router-link tag="span" :to="{path: '/sports_result', query: {s: new Date().getTime()}}" active-class="">게임결과</router-link>
                                    <router-link tag="span" :to="{path: '/sports_betinfo', query: {s: new Date().getTime()}}"  active-class="">참여내역</router-link>
                                    <router-link tag="span" :to="{path: '/betrule', query: {t: new Date().getTime()}}"  active-class="">게임가이드</router-link>
                                </div>
                               <!-- <div class="sub_menu">
                                    <router-link to="/sell_list" tag="span">거래소</router-link>
                                    <router-link to="/sell_sportsbetinfo" tag="span">판매등록</router-link>
                                    <router-link to="/sell_history" tag="span">판매내역</router-link>
                                    <router-link to="/buy_history" tag="span">구매내역</router-link>
                                </div>-->
                                <div class="sub_menu">
                                    <router-link :to="{path: '/recharge', query: {s: new Date().getTime()}}" tag="span">코인충전</router-link>
                                    <router-link :to="{path: '/recharge_history', query: {s: new Date().getTime()}}" tag="span">충전내역</router-link>
                                    <router-link :to="{path: '/item_shop', query: {s: new Date().getTime()}}" tag="span">아이템샵</router-link>
                                </div>
                                <div class="sub_menu">
                                    <router-link :to="{path: '/board', query: {s: new Date().getTime()}}" tag="span">자유게시판</router-link>
                                    <router-link :to="{path: '/analysis', query: {s: new Date().getTime()}}" tag="span" t>분석게시판</router-link>
                                    <router-link :to="{path: '/event', query: {s: new Date().getTime()}}" tag="span">이벤트</router-link>
                                </div>
                                <div class="sub_menu">
                                    <router-link :to="{path: '/notice', query: {s: new Date().getTime()}}" tag="span">공지사항</router-link>
                                    <router-link :to="{path: '/customer_center', query: {s: new Date().getTime()}}" tag="span">고객문의</router-link>
                                    <router-link :to="{path: '/faq', query: {s: new Date().getTime()}}" tag="span">FAQ</router-link>
                                    <router-link :to="{path: '/user_info', query: {s: new Date().getTime()}}" tag="span">회원정보</router-link>
                                </div>

                            </div>

                        </div>
                    </div>

                </div>

                <!--모바일-->
                <div class="mobile_menu_icon left_icon" @click="showMobileTopMenu">
                    <div class="icon">
                        <svg xmlns="http://www.w3.org/2000/svg"
                             xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 50 50" version="1.1">
                            <g id="surface1">
                                <path data-v-0632733e=""
                                      d="M 0 7.5 L 0 12.5 L 50 12.5 L 50 7.5 Z M 0 22.5 L 0 27.5 L 50 27.5 L 50 22.5 Z M 0 37.5 L 0 42.5 L 50 42.5 L 50 37.5 Z "></path>
                            </g>
                        </svg>
                    </div>
                </div>
                <div class="logo" @click="toMain">
                    <img src="../assets/images/logo/oneplay/oneplay_logo.png" alt="">
                    <div class="clock_topbar" style="margin-top: 3px">
                        <clock></clock>
                    </div>
                </div>
                <!--모바일-->
                <div class="mobile_menu_icon right_icon" @click="showMobileUserInfo">
                    <div class="icon">
                        <svg data-v-0632733e="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path data-v-0632733e=""
                                  d="M20.822 18.096c-3.439-.794-6.64-1.49-5.09-4.418 4.72-8.912 1.251-13.678-3.732-13.678-5.082 0-8.464 4.949-3.732 13.678 1.597 2.945-1.725 3.641-5.09 4.418-3.073.71-3.188 2.236-3.178 4.904l.004 1h23.99l.004-.969c.012-2.688-.092-4.222-3.176-4.935z"></path>
                        </svg>
                    </div>

                </div>
            </div>
        </div>
        <!--모바일 메뉴-->
        <mobile-top-menu></mobile-top-menu>
        <!--모바일회원정보-->
        <mobile-top-user-info></mobile-top-user-info>
    </div>

</template>

<script>
    import sportsConst from "../common/sportsConst";
    import leisureConst from "../common/leisureConst";
    import MobileTopMenu from "./mobile/MobileTopMenu";
    import MobileTopUserInfo from "./mobile/MobileTopUserInfo";
    import {getLastBuyItem, logout} from "../network/userRequest";
    import {INIT_USERINFO} from "../store/mutation-types";
    import Clock from "./Clock";

    export default {
        name: "TopbarComp",
        components: {Clock, MobileTopUserInfo, MobileTopMenu},
        data() {
            return {
                leisureConst,
                cross: sportsConst.GAME_DISPLAYPOSITION_1x2,
                handicap: sportsConst.GAME_DISPLAYPOSITION_HANDICAPOVERUNDER,
                special: sportsConst.GAME_DISPLAYPOSITION_SPECIAL,
                live: sportsConst.GAME_DISPLAYPOSITION_LIVE
            }
        },
        methods: {
            changeSportsDisplay(disp) {
                //SportsOriginal.vue -> $on
                //SportsLinksComp.vue ->on
                this.$bus.$emit('sportsDisplayChanged', disp);
            },
            showMobileTopMenu() {
                //모바일 메뉴아이콘 클릭시
                //MobileTopMenu.vue에서 $on
                this.$bus.$emit('mobileTopMenuActive')
            },
            showMobileUserInfo() {
                //모바일 유저아이콘 클릭시
                //MobileTopUserInfo.vue에서 $on
                this.$bus.$emit('mobileTopUserInfoActive')
            },
            logout() {
                this.$swal({
                    title: '로그아웃 하시겠습니까 ?',
                    type: 'question',
                    confirmButtonText: '로그아웃',
                }).then((res) => {
                    if (res.value) {
                        this.$store.commit(INIT_USERINFO);
                        window.sessionStorage.clear();
                        logout();
                        this.$router.replace("/login")
                    }
                })

            },
            toMain() {
                this.$router.replace("/main")
            }
        },
        created() {

            if(this.$store.state.isLogin){
                this.$store.dispatch('actionUserInfo')
                this.$store.dispatch('actionUnCheckedMessageCount')
                getLastBuyItem().then(res=>{
                    if(res.data.success){
                        this.$store.lastBuyItem = res.data.data
                    }
                })
            } else {
                this.$store.commit(INIT_USERINFO);
            }
        },
    }
</script>

<style scoped>

</style>

