<template>
  <div class="loading">
    <div class="loading_mask">
      <div class="icon">
        <img class=" animated rotateIn" alt="" src="../assets/images/logo/oneplay/loading_oneplay_logo.png">
      </div>
    </div>
  </div>

</template>

<script>
  export default {
    name: "LoadingComp"
  }
</script>

<style scoped>
  .loading .loading_mask{
    width: 100%;
    height: 100vh;
    position: fixed;
    background: rgba(0, 0, 0, 0.8);
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 999;
    display: table;
  }
  .loading .icon{
    position: absolute;
    width: 120px;
    left: 0;
    right:0;
    margin: 20% auto;
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
  }

  @-webkit-keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    from {
      transform: rotateY(0deg);
    }
    to {
     transform: rotateY(360deg);
    }
  }
  @media screen and (max-width: 800px) {
    .loading .icon{
      margin-top: 45%;
    }
  }
</style>