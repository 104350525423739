import Vue from 'vue'
import VueRouter from 'vue-router'



Vue.use(VueRouter)

const Login = () => import('../views/user/Login');
const Register = () => import('../views/user/Register');
const RegisterTerm = () => import('../views/user/RegisterTerm');
const Main = () => import('../views/user/Main');
const SportsOriginal = () => import('../views/sports/SportsOriginal');
const SportsGameResult = () => import('../views/sports/SportsGameResult');
const SportsBetInfo = () => import('../views/sports/SportsBetInfo');
const SellSportsBetInfo = () => import('../views/sports/SellSportsBetInfo');
const SellHistory = () => import('../views/sports/SellHistory');
const BuyHistory = () => import('../views/sports/BuyHistory');
const SellList = () => import('../views/sports/SellList');
const UserInfo = () => import('../views/user/UserInfo');
const Cancellation = () => import('../views/user/Cancellation');
const MessageInBox = () => import('../views/user/MessageInBox');
const MessageSend = () => import('../views/user/MessageSend');
const CustomerCenter = () => import('../views/user/CustomerCenter');
const Board = () => import('../views/user/Board');
const Notice = () => import('../views/user/Notice');
const Analysis = () => import('../views/user/Analysis');
const FAQ = () => import('../views/user/FAQ');
const Event = () => import('../views/user/Event');
const BetRule = () => import('../views/user/BetRule');
const ItemShop = () => import('../views/user/ItemShop');
const BoardDetail = () => import('../views/user/BoardDetail');
const EventDetail = () => import('../views/user/EventDetail');
const Recharge = () => import('../views/user/Recharge');
const Message = () => import('../views/user/Message');
const MessageSendHistory = () => import('../views/user/MessageSendHistory');
const SportsInplay = () => import('../views/inplay/SportsInplay');
const Tearm1 = () => import('../views/user/Tearm1');
const Tearm2 = () => import('../views/user/Tearm2');
const Tearm3 = () => import('../views/user/Tearm3');
const Tearm4 = () => import('../views/user/Tearm4');
const Tearm5 = () => import('../views/user/Tearm5');
const FindPwd = () => import('../views/user/FindPwd');
const Quota = () => import('../views/user/Quota');
const MyItems = () => import('../views/user/MyItems');
const ModifyUserInfo = () => import('../views/user/ModifyUserInfo');
const RealNameAuthentication = () => import('../views/user/RealNameAuthentication');
const RechargeHistory = () => import('../views/user/RechargeHistory');
const routes = [
    {
        path: '/',
        redirect: '/main'
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            title: '로그인'
        }
    },
    {
        path: '/register',
        name: 'register',
        component: Register,
        meta: {
            title: '회원가입'
        }
    },
    {
        path: '/register_term',
        name: 'registerTerm',
        component: RegisterTerm,
        meta: {
            title: '회원가입'
        }
    },
    {
        path: '/main',
        name: 'main',
        component: Main,
        meta: {
            title: '메인'
        }
    },
    {
        path: '/sports',
        name: 'sports',
        component: SportsOriginal,
        meta: {
            title: '스포츠'
        }
    },
    {
        path: '/sports_inplay',
        name: 'sports_inplay',
        component: SportsInplay,
        meta: {
            title: '실시간 스포츠'
        }
    },
    {
        path: '/sports_result',
        name: 'sports_result',
        component: SportsGameResult,
        meta: {
            title: '경기결과'
        }
    },
    {
        path: '/sports_betinfo',
        name: 'sports_betinfo',
        component: SportsBetInfo,
        meta: {
            title: '게임내역'
        }
    },
    {
        path: '/customer_center',
        name: 'customer_center',
        component: CustomerCenter,
        meta: {
            title: '고객센터'
        }
    },
    {
        path: '/board',
        name: 'board',
        component: Board,
        meta: {
            title: '게시판'
        }
    },
    {
        path: '/board_detail/:id',
        name: 'boardDetail',
        component: BoardDetail,
        meta: {
            title: '게시판'
        }
    },
    {
        path: '/analysis',
        name: 'analysis',
        component: Analysis,
        meta: {
            title: '게시판'
        }
    },
    {
        path: '/notice',
        name: 'notice',
        component: Notice,
        meta: {
            title: '공지사항'
        }
    },
    {
        path: '/faq',
        name: 'faq',
        component: FAQ,
        meta: {
            title: 'FAQ'
        }
    },
    {
        path: '/betrule',
        name: 'betrule',
        component: BetRule,
        meta: {
            title: '게임규칙'
        }
    },
    {
        path: '/event',
        name: 'event',
        component: Event,
        meta: {
            title: '이벤트'
        }
    },
    {
        path: '/event_detail/:id',
        name: 'eventDetail',
        component: EventDetail,
        meta: {
            title: '이벤트'
        }
    },
    {
        path: '/recharge',
        name: 'recharege',
        component: Recharge,
        meta: {
            title: '코인충전'
        }
    },
    {
        path: '/message',
        name: 'message',
        component: Message,
        meta: {
            title: '쪽지'
        }
    },
    {
        path: '/user_info',
        name: 'user_info',
        component: UserInfo,
        meta: {
            title: '내정보'
        }
    },
    {
        path: '/tearm1',
        name: 'tearm1',
        component: Tearm1,
        meta: {
            title: '이용약관'
        }
    },
    {
        path: '/tearm2',
        name: 'tearm2',
        component: Tearm2,
        meta: {
            title: '운영정책'
        }
    },
    {
        path: '/tearm3',
        name: 'tearm3',
        component: Tearm3,
        meta: {
            title: '개인정보처리방침'
        }
    }
    ,
    {
        path: '/tearm4',
        name: 'tearm4',
        component: Tearm4,
        meta: {
            title: '이메일무단수집거부'
        }
    }, 
    {
        path: '/tearm5',
        name: 'tearm5',
        component: Tearm5,
        meta: {
            title: '제휴문의 안내'
        }
    },
    {
        path: '/recharge_history',
        name: 'rechargeHistory',
        component: RechargeHistory,
        meta: {
            title: '코인충전로그'
        }
    },
    {
        path: '/sell_sportsbetinfo',
        name: 'sellSportsBetInfo',
        component: SellSportsBetInfo,
        meta: {
            title: '판매등록'
        }
    },
    {
        path: '/sell_history',
        name: 'sellHistory',
        component: SellHistory,
        meta: {
            title: '판매내역'
        }
    },
    {
        path: '/buy_history',
        name: 'buyHistory',
        component: BuyHistory,
        meta: {
            title: '구매내역'
        }
    },
    {
        path: '/sell_list',
        name: 'sellList',
        component: SellList,
        meta: {
            title: '거래소'
        }
    },
    {
        path: '/item_shop',
        name: 'itemShop',
        component: ItemShop,
        meta: {
            title: '아이템샵'
        }
    },
    {
        path: '/message_inbox',
        name: 'messageInBox',
        component: MessageInBox,
        meta: {
            title: '쪽지보관함'
        }
    },
    {
        path: '/message_send',
        name: 'messageSend',
        component: MessageSend,
        meta: {
            title: '쪽지보관함'
        }
    },
    {
        path: '/message_send_history',
        name: 'messageSendHistory',
        component: MessageSendHistory,
        meta: {
            title: '보낸쪽지함'
        }
    },
    {
        path: '/cancellation',
        name: 'cancellation',
        component: Cancellation,
        meta: {
            title: '회원탈퇴'
        }
    },
    {
        path: '/findpwd',
        name: 'findpwd',
        component: FindPwd,
        meta: {
            title: 'ID/PW 찾기'
        }
    },
    {
        path: '/realnameauth',
        name: 'realnameauth',
        component: RealNameAuthentication,
        meta: {
            title: '실명인증'
        }
    },
    {
        path: '/modifyuserinfo',
        name: 'modifyuserinfo',
        component: ModifyUserInfo,
        meta: {
            title: '내정보수정'
        }
    },
    {
        path: '/quota',
        name: 'quota',
        component: Quota,
        meta: {
            title: '손실한도설정'
        }
    },
    {
        path: '/myitems',
        name: 'myitems',
        component: MyItems,
        meta: {
            title: '내아이템'
        }
    }
]

const router = new VueRouter({
    routes,
    linkExactActiveClass: 'acvice_menu',
})


router.beforeEach((to, from, next) => {
    next();
});
const VueRouterPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(to) {
    return VueRouterPush.call(this, to).catch(err => err)
}


export default router
