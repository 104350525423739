let baseUrl = '';
let webSokcetUrl = '';

function getProductionUrl() {
  let prot= window.location.protocol
  let domain =  window.location.host
  return prot + '//' + domain
}
function getDevelopmentUrl() {
  let prot= window.location.protocol
  let domainOrigin =  window.location.host
  let domain = domainOrigin.slice(0,domainOrigin.lastIndexOf(':'))
  return prot + '//' + domain
}

switch (process.env.NODE_ENV) {
  case 'development':
    baseUrl = getDevelopmentUrl()+':7070/api/'
    webSokcetUrl = 'wss://oneplay.games/ws/sportswebsocket'
    break
  case 'production':
    baseUrl = getProductionUrl()+'/api/'
    webSokcetUrl = 'wss://oneplay.games/ws/sportswebsocket'
    break
}
export const BASE_URL = baseUrl
export const WEBSOCKET_URL = webSokcetUrl
export const TIMEOUT = 20000