import sportsConst from "../common/sportsConst";

export default {
    isShowTopbar: false,
    isLoading: false,
    displayPostion: sportsConst.GAME_DISPLAYPOSITION_1x2,
    isLogin: false,
    userInfo: {
        nickname: '',
        cash: 0,
        userPoint: 0,
        rank: {rankName: ''},
        myReferrer: {},
        referreCode: {},
        subordinateUsers: []
    },
    lastBuyItem: null,
    uncheckedMessageCount: 0,
    commonConfig: {
        rankConfig: {betMinLimitCash: 0},
        bonusConfig: {},
        crossRuleconfig: {}
    },
    sportsCartItems: [],
    inplayCartItems: [],
    sportsOriginal: [],
    sportsLive: [],
    minigames: {},
    selectedGameType: sportsConst.GAME_TYPE_ALL,
    selectedLeagueList: []
}