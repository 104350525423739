<template>
    <!--오른쪽 메뉴-->
    <div class="user_box">
        <div v-if="$store.state.isLogin">
            <table class="table table-bordered">
                <tbody>
                <tr>
                    <td class="bg-black-darker" style="width: 30%">
                        <img :src="$store.state.userInfo.rank.imageUrl" width="45" v-if="$store.lastBuyItem == null">
                        <img :src="require(`@/assets/images/item/oneplay/${$store.lastBuyItem}.png`)"
                             style="width: 45px;" v-if="$store.lastBuyItem != null">
                    </td>
                    <td class="text-left bg-black-darker" style="width: 70%">
                        <table class="table" style="font-size: 12px">
                            <tr style="border-bottom: 1px solid #000000">
                                <td class="text-left" style="padding-left: 4px"><span class="text-warning">{{$store.state.userInfo.nickname}}</span>
                                    님 {{$store.state.userInfo.rank.rankName}}
                                </td>
                            </tr>
                            <tr style="border-bottom: 1px solid #000000">
                                <td class="text-left text-success" style="padding-left: 4px">보유코인 <span
                                        class="text-white">{{$store.state.userInfo.cash|comma}}</span>
                                </td>
                            </tr>
                            <tr style="border-bottom: 1px solid #000000">
                                <td class="text-left text-success" style="padding-left: 4px">보유BP <span
                                        class="text-white">{{$store.state.userInfo.userPoint|comma}}</span>
                                </td>
                            </tr>
                            <tr style="border-bottom: 0px solid #000000">
                                <td class="text-left text-success" style="padding-left: 4px">참여취소권 <span
                                        class="text-white"> {{$store.state.userInfo.itemcancelbet|comma}}</span>
                                </td>
                            </tr>

                        </table>
                    </td>
                </tr>
                </tbody>
            </table>
            <table class="table table-bordered" style="font-size: 12px;margin-top: 2px">
                <tbody>
                <tr>
                    <router-link to="/recharge" tag="td" class="text-center text-white cursor lh25">
                        <i class="fa fa-won text-warning"></i> 코인충전
                    </router-link>
                    <router-link to="/sports_betinfo" tag="td" class="text-center text-white cursor">
                        <i class="fa fa-file-image-o text-success"></i> 참여내역
                    </router-link>
                    <router-link to="/user_info" tag="td" class="text-center text-white cursor">
                        <i class="fa fa-user-circle-o text-primary"></i> 회원정보
                    </router-link>
                </tr>
                <tr>
                    <router-link to="/customer_center" tag="td" class="text-center text-white cursor lh25">
                        <i class="fa fa-comments-o text-warning"></i> 문의
                    </router-link>
                    <router-link to="/message" tag="td" class="text-center text-white cursor">
                        <i class="fa fa-envelope text-success"></i> 쪽지
                    </router-link>
                    <router-link to="" @click.native="logout" tag="td" class="text-center text-white cursor">
                        <i class="fa fa-sign-out text-primary"></i> 로그아웃
                    </router-link>
                </tr>
                </tbody>
            </table>
        </div>
        <div v-if="!$store.state.isLogin">
            <div class="user_panel">
                <div class="input_panel">
                    <div>
                        <input type="text" v-model="user.username" placeholder="아이디">
                    </div>
                    <div>
                        <input type="password" v-model="user.passwd" placeholder="패스워드">
                    </div>
                </div>
                <div class="button_panel">
                    <button class="btn btn02" @click="doLogin">
                        로그인
                    </button>
                </div>
                <div class="join_pw_panel">
                    <router-link tag="span" to="/findpwd">ID/PW 찾기</router-link>
                    <router-link to="/register_term" tag="span" style="color: #f19f09">회원가입</router-link>
                    <router-link to="/realnameauth" tag="span">실명인증</router-link>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import {logout, changePoint, login, getUserInfo, getLastBuyItem} from "../network/userRequest";
    import {INIT_USERINFO, RECEIVE_IS_LOGIN, RECEIVE_USER_INFO} from "../store/mutation-types";
    import sportsConst from "../common/sportsConst";

    export default {
        name: "UserInfoComp",
        data() {
            return {isShowSubordinate: false, user: {username: '', passw: ''}}
        },
        methods: {
            doLogin() {
                login(this.user).then(res => {
                    if (res.data.success) {
                        getUserInfo().then(res => {
                            this.$store.commit(RECEIVE_IS_LOGIN, true);
                            getLastBuyItem().then(res => {
                                if (res.data.success) {
                                    this.$store.lastBuyItem = res.data.data
                                }
                            })
                            this.$store.state.isLogin = true
                            let userInfo = res.data.data
                            this.$store.commit(RECEIVE_USER_INFO, {userInfo});
                            this.$router.push('/main').catch(error => {
                                console.info(error.message)
                            });
                        }, err => {
                            console.log(err)
                        })
                    } else {
                        this.$swal({
                            title: res.data.msg,
                            type: 'warning',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                        if (res.data.status === sportsConst.AUTH_DATE_OVER) {
                            this.$router.push('/realnameauth').catch(error => {
                                console.info(error.message)
                            });
                        }
                    }
                })
            },
            logout() {
                this.$swal({
                    title: '로그아웃 하시겠습니까 ?',
                    type: 'question',
                    confirmButtonText: '로그아웃',
                }).then((res) => {
                    if (res.value) {
                        this.$store.commit(INIT_USERINFO);
                        window.sessionStorage.clear();
                        logout();
                        this.$router.replace("/login")
                    }
                })

            },
            initUserInfo() {
                this.$store.dispatch('actionUserInfo')
            },
            initUncheckedMessage() {
                this.$store.dispatch('actionUnCheckedMessageCount')
            },
            changePoint() {
                changePoint().then(res => {
                    if (!res.data.success) {
                        this.$swal({
                            title: res.data.msg,
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    } else {
                        this.initUserInfo()
                    }
                })
            },
            refreshCasinoCash() {

            }
        },
        created() {

        }
    }
</script>

<style scoped>

    .user_panel {
        width: 100%;
        border: 1px solid #0c0e0e;
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 5px 5px;
        --background-color: #242a30;
        flex-wrap: wrap;
    }

    .user_panel .input_panel {
        width: 69.6%;
        height: 60px;
        text-align: left;
    }

    .user_panel .input_panel div {
        width: 100%;
        line-height: 30px;
    }

    .user_panel .input_panel input {
        width: 100%;
        text-align: left;
        box-sizing: border-box;
        padding-left: 4px;
        height: 26px;
        color: #0c0e0e;

    }

    .user_panel .button_panel {
        width: 30%;
        height: 60px;
        box-sizing: border-box;
        padding: 5px 5px;
    }

    .button_panel button {
        width: 60px;
        height: 50px;
    }

    .user_panel .join_pw_panel {
        width: 100%;
        line-height: 38px;
        justify-content: space-between;
        text-align: left;
    }

    .user_panel .join_pw_panel span {
        width: 33%;
        display: inline-block;
        text-align: center;
        color: #c4c4c3;
        cursor: pointer;
    }

    .user_panel .join_pw_panel span:hover {
        color: #ffffff;
    }


    @media screen and (max-width: 800px) {
        .user_box {
            display: none !important;
        }
    }
</style>