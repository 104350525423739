import http from "./axios/http";

/*로그인*/
export function login(user) {
    return http.post('/user/login', user)
}

/*로그아웃*/
export function logout() {
    return http.get('/user/logout')
}

/*회원가입시 아이디중복 체크*/
export function checkUserName(username) {
    return http.post('/user/check_username', {'username': username})
}

/*회원가입시 닉네임중복 체크*/
export function checkNickName(nickname) {
    return http.post('/user/check_nickname', {'nickname': nickname})
}


/*회원가입*/
export function register(user) {
    return http.post('/user/register', user)
}

/*회원정보*/
export function getUserInfo() {
    return http.get('/user/user_info')
}

/*회원추천인포인트내역*/
export function referrerPointLog(pageNum, pageSize) {
    return http.get('/user/referrer_point_log?pageNum=' + pageNum + '&pageSize=' + pageSize)
}

/*비밀번호변경*/
export function changePasswd(user) {
    return http.post('/user/change_passwd', user)
}

/*회원 포인트 전환*/
export function changePoint() {
    return http.get('/user/change_point')
}


/*1:1문의 리스트*/
export function getQuestionList(pageNum, pageSize, orderBy) {
    return http.post('/customercenter/list?pageNum=' + pageNum + '&pageSize=' + pageSize + '&orderBy=' + orderBy)
}

export function saveQuestion(customerBody) {
    return http.post('/customercenter/save', customerBody)
}

/*게시판*/
export function saveBoard(board) {
    return http.post('/board/save', board)
}

export function saveBoardComment(comment) {
    return http.post('/board/save_comment', comment)
}

export function getBoardList(pageNum, pageSize) {
    return http.get('/board/board_list?pageNum=' + pageNum + '&pageSize=' + pageSize)
}

/*공지사항*/
export function getNoticeList(pageNum, pageSize) {
    return http.get('/board/notice?pageNum=' + pageNum + '&pageSize=' + pageSize)
}

export function getFAQList(pageNum, pageSize) {
    return http.get('/board/faq?pageNum=' + pageNum + '&pageSize=' + pageSize)
}

export function getAnalysisList(pageNum, pageSize) {
    return http.get('/board/analysis?pageNum=' + pageNum + '&pageSize=' + pageSize)
}

/*게임규정*/
export function getBetRuleList(pageNum, pageSize) {
    return http.get('/board/betrule?pageNum=' + pageNum + '&pageSize=' + pageSize)
}

/*게임규정*/
export function getServiceMentList(serviceType) {
    return http.get('/board/service_ment?serviceType=' + serviceType)
}

export function getBoardDetail(id) {
    return http.get('/board/detail?id=' + id)
}

export function getEvent(pageNum, pageSize) {
    return http.get('/board/event?pageNum=' + pageNum + '&pageSize=' + pageSize)
}

export function getEventDetail(id) {
    return http.get('/board/event_detail?id=' + id)
}

/*코인충*/
export function getApplyCashList(search, pageNum, pageSize) {
    return http.post('/applycash/list?pageNum=' + pageNum + '&pageSize=' + pageSize, search)
}

export function rechargeCash(applyCash) {
    return http.post('/applycash/recharge', applyCash)
}


export function deleteApply(id) {
    return http.get('/applycash/delete_by_id?id=' + id)
}

export function getHando() {
    return http.get('/applycash/get_hando')
}

export function cancelApply(id) {
    return http.get('/applycash/cancel_by_id?id=' + id)
}
export function getRechargeInfomation() {
    return http.get('/applycash/get_recharge_infomation')
}

/***쪽지***/
export function getMessageList(search, pageNum, pageSize) {
    return http.post('/message/message_list?pageNum=' + pageNum + '&pageSize=' + pageSize, search)
}

export function checkedMessageById(id) {
    return http.get('/message/chekced_by_id?id=' + id)
}

export function deleteMessageById(id) {
    return http.get('/message/delete_by_id?id=' + id)
}

export function checkedMessageAll() {
    return http.get('/message/checked_all')
}

export function deleteMessageAll() {
    return http.get('/message/delete_all')
}

export function move2box(id) {
    return http.get('/message/move2box?id=' + id)
}

export function getUnCheckedMessageCount() {
    return http.get('/message/message_count')
}

export function useMessagePack(type) {
    return http.get('/message/use_message_pack?type=' + type)
}

export function sendMessageByNinames(message) {
    return http.post('/message/send_by_nicknames', message)
}
export function sendMessageRandom(message) {
    return http.post('/message/send_message_random', message)
}
export function getSendMessage( pageNum, pageSize) {
    return http.get('/message/get_send_message?pageNum=' + pageNum + '&pageSize=' + pageSize)
}

export function getPopupList() {
    return http.get('/user/popup_list')
}
export function getItemList(search, pageNum, pageSize) {
    return http.post('/itemshop/list?pageNum=' + pageNum + '&pageSize=' + pageSize, search)
}
export function buyItem(search, pageNum, pageSize) {
    return http.post('/itemshop/buy_item', search)
}

export function getLoginHistory(pageNum, pageSize) {
    return http.get('/user/login_history?pageNum=' + pageNum + '&pageSize=' + pageSize)
}
//
export function cancellation(user) {
    return http.post('/user/cancellation',user)
}

export function setQuote(quota) {
    return http.get('/user/set_quote?quota=' + quota)
}
export function setDenyLogin(month) {
    return http.get('/user/set_deny_login?month=' + month)
}
//
export function getBetStatistic(search) {
    return http.post('/user/get_bet_statistic', search)
}


export function buyItemHistory() {
    return http.get('/user/buy_item_history')
}
export function useItemHistory() {
    return http.get('/user/use_item_history')
}
export function getLastBuyItem() {
    return http.get('/user/get_last_buy_item')
}


export function changeUserNickname(search) {
    return http.post('/user/change_user_nickname', search)
}
export function changeUserEmail(search) {
    return http.post('/user/change_user_email', search)
}
export function changeUserPasswd(search) {
    return http.post('/user/change_user_passwd', search)
}
export function getSencdata(type) {
    return http.get('/niceid/get_sencdata?type='+type)
}
export function findId() {
    return http.get('/user/find_id')
}
export function findPwd(username) {
    return http.get('/user/find_pwd?username='+username)
}
